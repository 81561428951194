<template>
  <div>
    <metainfo>
      <template v-slot:title="{ content }">{{ content ? `${content} | Lorentz Engineering` : `Lorentz Engineering` }}</template>
    </metainfo>
    <router-view/>
  </div>
</template>


<script>
import {RouterView} from 'vue-router';
import { useMeta } from 'vue-meta'

export default {
  name: 'App',
  components: {
    RouterView,
  },
  setup () {
    useMeta({
      title: '',
      htmlAttrs: { lang: 'en', amp: true }
    })
  }
}
</script>

<style>
.container {
  width: 100%;
}

@media (min-width: 576px) {
  .container {
    max-width: 576px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 992px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
</style>
