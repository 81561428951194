import {createApp} from 'vue'
import App from './App.vue'
import router from './router/main'
import './assets/tailwind.css'
import {createMetaManager} from 'vue-meta'
import VueGtag from "vue-gtag";

// import common from './common.js'

const app = createApp(App);

app.use(router);
app.use(createMetaManager());
app.use(VueGtag, {
    config: {id: "G-B5LZB0FL62",
        send_page_view: true},
    enabled: true

});

// app.mixin(common);
app.mixin({
    methods: {
        getFile: function(path) {
            return new Promise(function (resolve) {
                var xhr = new XMLHttpRequest();
                xhr.open("GET", path, true);
                xhr.onload = function () {
                    resolve(xhr.responseText);
                };
                xhr.send();
            });
        },
        getAsset: function(path) {
            return require('@/assets/' + path);
        },
        getSlidesPage: function (page) {
            const path = `./${page}/`;
            let slides = require.context(
                `./assets/carousel/`,
                true,
                /^(?!.*disabled).*\.(png|jpe?g|svg|jpg|webp)$/
            ).keys();


            slides = slides.filter((slide) => {
                return slide.includes(path);
            });

            slides = slides.map((slide) => {
                return {
                    src: "carousel/" + slide.replace("./", ""),
                    align: slide.includes("__ALIGNTOP__") ? "top" : slide.includes("__ALIGNBOTTOM__") ? "bottom" : "center",
                };
            });

            if (slides.length === 0) {
                return null;
            }

            return slides;
        }
    }
})

app.config.productionTip = false

app.mount('#app')